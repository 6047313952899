<script setup lang="ts">
import { watch } from "vue";

const props = defineProps<{ show: boolean }>();
const emit = defineEmits<{
  show: [];
  hide: [];
}>();
watch(
  () => props.show,
  (newShow) => {
    if (newShow) {
      emit("show");
    } else {
      emit("hide");
    }
  },
);
</script>

<template>
  <div v-show="false" />
</template>
