<script setup lang="ts">
const props = defineProps<{ isLoading: boolean; hasMore: boolean }>();
const emit = defineEmits<{ loadMore: [] }>();
function onClick() {
  if (props.isLoading || !props.hasMore) return;
  emit("loadMore");
}
</script>

<template>
  <button v-if="hasMore" class="btn-outline" :disabled="isLoading" @click="onClick">
    {{ __("Load more", "gaspesia") }}
    <IconLucideLoader v-if="isLoading" class="icon-md animate-spin" />
    <IconLucideArrowRight v-else class="icon-md" />
  </button>
</template>
