import "@valibot/i18n/fr";

import { type Plugin } from "vue";
import VueSocialSharing from "vue-social-sharing";

export default {
  install(app) {
    app.use(VueSocialSharing);
  },
} as Plugin;
