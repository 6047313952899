<script setup lang="ts">
import { useBlog } from "@/composables/blog";

const { articles, filters, isLoading, hasMore, loadMore } = useBlog();
</script>

<template>
  <section id="the-blog" class="@container">
    <div class="container flex flex-col gap-12 py-12">
      <InputSearch v-model="filters.search" class="self-end max-w-screen-sm" />
      <div class="grid @md:grid-cols-2 @xl:grid-cols-3 gap-6">
        <template v-for="(article, i) of articles" :key="i">
          <BlogArticleCard :article="article" />
        </template>
      </div>
      <UiLoadMoreButton :is-loading="isLoading" :has-more="hasMore" class="self-center" @load-more="loadMore" />
    </div>
  </section>
</template>
