<script setup lang="ts">
import { useSiteOptions } from "@/composables/site-options";

const { registration_link } = useSiteOptions();
</script>

<template>
  <a :href="registration_link.url" :target="registration_link.target" class="site-registration-link">
    {{ registration_link.title }}
  </a>
</template>
