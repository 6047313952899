<script setup lang="ts">
import { useLocales } from "@/composables/locales";

const { current } = useLocales();
</script>

<template>
  <a :href="current.url" class="block">
    <SvgLogoGaspesiaDesktop class="w-20 h-20 lg:w-64 lg:h-64 object-contain fill-white drop-shadow-logo" />
  </a>
</template>
