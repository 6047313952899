<script setup lang="ts">
import { useLocales } from "@/composables/locales";
import { type EventScheduleDay } from "@/schemas/event-schedule-day";

const { formatDate } = useLocales();
defineProps<{
  title: string;
  subtitle?: string;
  days: EventScheduleDay[];
}>();

function formatDay(date: string) {
  return formatDate(date, { weekday: "long" }).replace(/^\w/, (c) => c.toUpperCase());
}
</script>

<template>
  <div class="section-event-schedule pb-12">
    <UiSectionHeader :title="title" :subtitle="subtitle" />
    <div class="container-lg">
      <UiAccordion>
        <UiAccordionItem v-for="(day, i) in days" :key="i" :title="formatDay(day.date)">
          <div class="space-y-3">
            <div v-if="day.instructions.length" class="prose" v-html="day.instructions" />
            <div class="divide-y divide-neutral-400 -my-6 [&>*]:py-6">
              <EventScheduleItem v-for="(item, j) in day.items" :key="j" :item="item" />
            </div>
          </div>
        </UiAccordionItem>
      </UiAccordion>
    </div>
  </div>
</template>
