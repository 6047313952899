<script setup lang="ts">
import IconFacebook from "@fortawesome/fontawesome-free/svgs/brands/facebook-f.svg";
import IconFlickr from "@fortawesome/fontawesome-free/svgs/brands/flickr.svg";
import IconInstagram from "@fortawesome/fontawesome-free/svgs/brands/instagram.svg";
import IconLinkedin from "@fortawesome/fontawesome-free/svgs/brands/linkedin-in.svg";
import IconPinterest from "@fortawesome/fontawesome-free/svgs/brands/pinterest-p.svg";
import IconTiktok from "@fortawesome/fontawesome-free/svgs/brands/tiktok.svg";
import IconTwitter from "@fortawesome/fontawesome-free/svgs/brands/x-twitter.svg";
import IconYoutube from "@fortawesome/fontawesome-free/svgs/brands/youtube.svg";
import IconGlobe from "lucide-static/icons/globe.svg";

import type { AcfSocialMedia } from "@/schemas/acf-social-media";

defineProps<{ items: AcfSocialMedia[] }>();

const icons: Record<string, any> = {
  facebook: IconFacebook,
  flickr: IconFlickr,
  instagram: IconInstagram,
  linkedin: IconLinkedin,
  pinterest: IconPinterest,
  tiktok: IconTiktok,
  twitter: IconTwitter,
  youtube: IconYoutube,
};

function getComponent(icon: string) {
  return icons[icon] || IconGlobe;
}
</script>

<template>
  <ul class="social-medias inline-flex flex-wrap items-center gap-2">
    <li v-for="item in items" :key="item.url">
      <a :href="item.url" target="_blank" rel="noopener noreferrer" class="btn-circle-social">
        <Component :is="getComponent(item.icon)" />
      </a>
    </li>
  </ul>
</template>
