<script setup lang="ts">
import { Dialog, DialogPanel } from "@headlessui/vue";
import { ref } from "vue";

import { useResponsive } from "@/composables/responsive";

const isOpen = ref(false);
const { isPhone } = useResponsive();
</script>

<template>
  <header id="site-header" class="relative z-header">
    <SiteHeaderLogo class="absolute top-4 left-4" />
    <UiHamburgerButton :open="isOpen" class="fixed wp-top-6 right-0 z-menu" @click="isOpen = !isOpen" />
    <Dialog :open="isOpen" as="div" @close="isOpen = false">
      <Transition appear enter-active-class="duration-300 ease-out" enter-from-class="opacity-0" leave-active-class="duration-200 ease-in" leave-to-class="opacity-0">
        <DialogPanel class="fixed wp-top-0 inset-0 z-menu">
          <div class="h-full flex flex-col sm:items-center gap-6 overflow-y-scroll dark p-4 color-dark bg-contour-cover">
            <div class="self-stretch flex items-center gap-6 pr-16">
              <SvgLogoGaspesiaDesktop class="flex-shrink-0 mr-auto w-20 h-20 object-contain fill-white" />
              <SiteRegistrationLink v-if="!isPhone" class="btn-registration-lg" />
              <LocaleLinks class="font-bold links:link-gray" />
              <UiHamburgerButton :open="isOpen" class="fixed wp-top-6 right-0" @click="isOpen = !isOpen" />
            </div>
            <SiteHeaderMenu class="w-full sm:w-96" @navigate="isOpen = false" />
            <SiteRegistrationLink v-if="isPhone" class="btn-registration-lg" />
            <SiteContactSocialMedias class="w-full sm:w-96" />
          </div>
        </DialogPanel>
      </Transition>
    </Dialog>
    <slot />
  </header>
</template>
