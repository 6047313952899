<script setup lang="ts">
import { type AcfLink } from "@/schemas/acf-link";
import { type EventRace } from "@/schemas/event-race";
import { type Image, imageBgStyle } from "@/schemas/image";

const props = defineProps<{
  photo: Image;
  race: EventRace;
  registrationUrl: string | null;
}>();
const registrationLink: AcfLink | null = props.registrationUrl
  ? {
      title: wp.i18n.__("Registration", "gaspesia"),
      url: props.registrationUrl,
      target: "_blank",
    }
  : null;
</script>

<template>
  <div class="event-race-card relative min-w-72 flex flex-col bg-white shadow-card">
    <div class="px-6 pb-12 bg-cover bg-overlay-event mask-slant-bottom" :class="race.show_message ? 'pt-12' : 'pt-4'" :style="imageBgStyle(photo)">
      <div v-if="race.show_message" class="absolute top-0 inset-x-0 px-3 py-1.5 bg-pink text-white font-semibold leading-tight text-center">{{ race.message }}</div>
      <div class="relative text-white drop-shadow-logo">
        <h3 class="heading-h3">{{ race.title }}</h3>
        <div class="heading-subtitle">{{ race.subtitle }}</div>
      </div>
    </div>
    <div class="relative flex-grow px-6 py-4 flex flex-col gap-3">
      <UiPartner v-if="race.partner" :partner="race.partner" class="w-32" />
      <ul v-if="race.maps.length" class="leading-tight">
        <li v-for="(map, i) of race.maps" :key="i" class="">
          <UiLink :link="map.link" class="link-event-map" />
        </li>
      </ul>
    </div>

    <UiLink v-if="registrationLink" :link="registrationLink" class="absolute bottom-0 right-0 translate-y-1/2 btn-registration-sm" />
  </div>
</template>
