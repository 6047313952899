import { type I18n } from "@wordpress/i18n";
import { type Plugin } from "vue";

declare global {
  const wp: { i18n: I18n };
}

declare module "vue" {
  interface ComponentCustomProperties {
    __: typeof wp.i18n.__;
  }
}

export default {
  install(app) {
    app.config.globalProperties.__ = wp.i18n.__;
  },
} as Plugin;
