import { watchDebounced } from "@vueuse/core";
import { computed, onMounted, ref } from "vue";

import { type BlogArticle } from "@/schemas/blog";

export function useBlog() {
  const articles = ref<BlogArticle[]>([]);
  const curPage = ref(1);
  const filters = ref({ search: "" });
  const isLoading = ref(false);
  const totalArticles = ref(1);
  const hasMore = computed(() => articles.value.length < totalArticles.value);

  function loadMore() {
    if (isLoading.value || !hasMore.value) return;
    curPage.value++;
    load();
  }

  async function load() {
    try {
      isLoading.value = true;
      const response = await fetch(blog.ajaxUrl, {
        method: "POST",
        body: new URLSearchParams({
          page: curPage.value.toString(),
          perPage: "6",
          ...filters.value,
        }),
      });
      const result = await response.json();
      if (!result.success) {
        throw new Error("Could not load articles.");
      }
      totalArticles.value = parseInt(response.headers.get("X-Total-Articles") || "0");
      if (curPage.value === 1) {
        articles.value = result.data;
      } else {
        articles.value = [...articles.value, ...result.data];
      }
    } catch (error) {
      console.log(error);
    } finally {
      isLoading.value = false;
    }
  }

  onMounted(() => {
    watchDebounced(
      () => ({ ...filters.value }),
      (newFilters, oldFilters) => {
        console.log(JSON.stringify({ newFilters, oldFilters }));
        if (JSON.stringify(newFilters) !== JSON.stringify(oldFilters)) {
          curPage.value = 1;
        }
        load();
      },
      { immediate: true, deep: true, debounce: 250 },
    );
  });

  return { articles, filters, isLoading, hasMore, loadMore };
}
