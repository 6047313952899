<script setup lang="ts">
import { useSiteOptions } from "@/composables/site-options";

const { name } = useSiteOptions();
</script>

<template>
  <div class="site-footer-copyright">
    © {{ new Date().getFullYear() }}
    {{ name }}
  </div>
</template>
