<script setup lang="ts">
import { tv } from "tailwind-variants";

import { type Image, imageBgStyle } from "@/schemas/image";

const props = defineProps<{
  photo: Image;
  reverse: boolean;
}>();

const sectionContentPhotosVariants = tv({
  slots: {
    wrapper: "flex flex-col @lg:flex-row",
    photo: "flex-grow aspect-[1/1] @sm:aspect-[2/1] @lg:aspect-auto bg-cover mask-slant-bottom",
    content: "@lg:container-1/2 py-12 @lg:py-24",
  },
  variants: {
    reverse: {
      false: {
        photo: "@lg:mask-slant-right",
        content: "container-right pl-12",
      },
      true: {
        photo: "@lg:order-last @lg:mask-slant-left",
        content: "container-left pr-12",
      },
    },
  },
});

const classes = sectionContentPhotosVariants({ reverse: props.reverse });
</script>

<template>
  <div class="section-content-photo @container">
    <div :class="classes.wrapper()">
      <div :class="classes.photo()" :style="imageBgStyle(photo)" />
      <div :class="classes.content()">
        <slot />
      </div>
    </div>
  </div>
</template>
