<script setup lang="ts">
import { useLocales } from "@/composables/locales";
import { type AcfLink } from "@/schemas/acf-link";
import { type EventPage } from "@/schemas/event-page";
import { imageMaskStyle } from "@/schemas/image";

defineProps<{
  title: string;
  subtitle: string;
  events: EventPage[];
  readmoreLink: AcfLink;
}>();

const { formatDateRange } = useLocales();
</script>

<template>
  <div class="section-list-events">
    <UiSectionHeader :title="title" :subtitle="subtitle" />
    <UiSplitContainer v-for="(event, i) of events" :key="i" :image="event.image" :color="event.details.color" :reverse="!!(i % 2)">
      <div class="flex flex-col items-center gap-6 py-12 text-center">
        <div class="w-48 h-48 mask-image-center mask-no-repeat mask-size-contain bg-current" :style="imageMaskStyle(event.details.logo)" />
        <div class="">
          <div class="heading-h3">{{ formatDateRange(event.schedule.start_date, event.schedule.end_date) }}</div>
          <div class="heading-subtitle">{{ event.schedule.city }}</div>
        </div>
        <div class="">{{ event.excerpt }}</div>
        <div class="flex flex-col gap-3 w-64">
          <a :href="event.url" class="btn-outline">{{ __("Registration", "gaspesia") }}</a>
        </div>
      </div>
    </UiSplitContainer>
    <UiSectionReadmore :link="readmoreLink" />
  </div>
</template>
