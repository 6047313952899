<script setup lang="ts">
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuItem, MenuItems } from "@headlessui/vue";
import { ref } from "vue";

import { useSiteOptions } from "@/composables/site-options";

defineEmits<{ navigate: [] }>();

const { mainMenuItems } = useSiteOptions();

// Close other Disclosures when opening a new one
const disclosures = ref<Record<number, Element>>({});
const closeOthers = (id: number) => {
  Object.entries(disclosures.value)
    .filter(([key]) => Number(key) !== id)
    .forEach(([, el]) => (el as HTMLButtonElement).click());
};
</script>

<template>
  <Menu>
    <MenuItems static class="flex flex-col gap-5">
      <template v-for="item of mainMenuItems" :key="item.id">
        <MenuItem v-if="item.children.length === 0" class="menu-item is-level-1" :class="item.class" :style="`--color-event: ${item.color || '#fff'}`">
          <a :href="item.url" :target="item.target" @click="$emit('navigate')">
            {{ item.title }}
          </a>
        </MenuItem>
        <Disclosure v-else v-slot="{ open, close }">
          <DisclosureButton class="menu-item is-level-1" :class="open && 'is-opened'">
            {{ item.title }}
            <IconLucideChevronRight class="ui-open:rotate-90" />
          </DisclosureButton>
          <Transition
            enter-active-class="transition duration-100 ease-out"
            enter-from-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-out"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0"
          >
            <DisclosurePanel class="flex flex-col gap-3 pl-3">
              <template v-for="child in item.children" :key="child.id">
                <MenuItem as="div" class="menu-item is-level-2" :class="child.class" :style="`--color-event: ${child.color || '#fff'}`">
                  <a :href="child.url" :target="child.target" @click="$emit('navigate')">
                    {{ child.title }}
                  </a>
                </MenuItem>
              </template>
            </DisclosurePanel>
          </Transition>
          <button v-show="false" :ref="(el) => (disclosures[item.id] = <Element>el)" @click="close" />
          <UiDisclosureStateEmitter :show="open" @show="closeOthers(item.id)" />
        </Disclosure>
      </template>
    </MenuItems>
  </Menu>
</template>
