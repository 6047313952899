<script setup lang="ts">
import { useSiteOptions } from "@/composables/site-options";

const { footerMenuItems } = useSiteOptions();
</script>

<template>
  <ul class="flex flex-col gap-3">
    <li v-for="item in footerMenuItems" :key="item.id" :class="['menu-item is-level-2', item.class]" :style="`--color-event: ${item.color || '#fff'}`">
      <a :href="item.url" :target="item.target">{{ item.title }}</a>
    </li>
  </ul>
</template>
