<script setup lang="ts">
import { useLocales } from "@/composables/locales";

const { current, available } = useLocales();
const locales = available.filter((locale) => locale.code !== current.code);
</script>

<template>
  <div class="locale-links">
    <a v-for="locale in locales" :key="locale.code" :href="locale.url">
      {{ locale.code.toUpperCase() }}
    </a>
  </div>
</template>
