import "@valibot/i18n/fr";

import * as v from "valibot";
import { type Plugin } from "vue";

export default {
  install() {
    v.setGlobalConfig({ lang: "fr" });
  },
} as Plugin;
