import * as v from "valibot";

export const imageSchema = v.object({
  url: v.pipe(v.string(), v.url()),
  position: v.string(),
  alt: v.string(),
});
export type Image = v.InferOutput<typeof imageSchema>;

export function imageBgStyle(image: Image): string {
  if (!image.url) return "";
  return `background-image: url(${image.url}); background-position: ${image.position};`;
}

export function imageMaskStyle(image: Image): string {
  if (!image.url) return "";
  return `mask-image: url(${image.url});`;
}

export function imagePositionStyle(image: Image): string {
  if (!image.url) return "";
  return `object-position: ${image.position};`;
}
