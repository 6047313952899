<script setup lang="ts">
import { useLocales } from "@/composables/locales";
import { type EventPage } from "@/schemas/event-page";
import { imageBgStyle } from "@/schemas/image";

defineProps<{ event: EventPage }>();

const { formatDateRange } = useLocales();
</script>

<template>
  <div class="event-page-card flex flex-col bg-white shadow-card" :style="`--color-event: ${event.details.color || '#fff'}`">
    <div class="aspect-[3/2] bg-cover mask-slant-bottom" :style="imageBgStyle(event.image)" />
    <div class="relative flex-grow p-6 pb-10 flex flex-col gap-3">
      <a :href="event.url" class="link-opacity absolute -top-3 right-6">
        <SvgIconArrowRight class="icon-xl text-muted fill-current" />
      </a>
      <div class="self-start badge-event">{{ `${event.schedule.city} - ${formatDateRange(event.schedule.start_date, event.schedule.end_date)}` }}</div>
      <h3 class="heading-h4">{{ event.title }}</h3>
      <p class="line-clamp-3">{{ event.excerpt }}</p>
    </div>
  </div>
</template>
