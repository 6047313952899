<script setup lang="ts">
import { tv } from "tailwind-variants";

import { useSiteOptions } from "@/composables/site-options";

const siteContactVariants = tv({
  slots: {
    wrapper: "flex flex-col gap-2 links:link-prose",
    item: "flex items-start gap-2 text-left",
    icon: "w-6 h-6 fill-current",
  },
});

const classes = siteContactVariants();

const { name, address, phone, email } = useSiteOptions();
</script>

<template>
  <div class="site-footer-contact" :class="classes.wrapper()">
    <div :class="classes.item()">
      <IconLucideMapPin :class="classes.icon()" />
      <div class="">
        <div class="font-bold">{{ name }}</div>
        <UiAddress :value="address" />
      </div>
    </div>
    <div :class="classes.item()">
      <IconLucidePhone :class="classes.icon()" />
      <UiPhone :value="phone" />
    </div>
    <div :class="classes.item()">
      <IconLucideMail :class="classes.icon()" />
      <UiEmail :value="email" />
    </div>
    <slot class="mt-6" />
  </div>
</template>
