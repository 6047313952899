<script setup lang="ts">
import { useAuth } from "@/composables/auth";

const { isLoggedIn, loginUrl, logoutUrl } = useAuth();
</script>

<template>
  <a :href="isLoggedIn ? logoutUrl : loginUrl" class="auth-link">
    {{ isLoggedIn ? __("Logout", "gaspesia") : __("Login", "gaspesia") }}
  </a>
</template>
