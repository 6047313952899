<script setup lang="ts">
import "@splidejs/vue-splide/css/core";

// @ts-expect-error - VueSplide types are not available
import { Splide, SplideSlide, SplideTrack } from "@splidejs/vue-splide";

import { useLocales } from "@/composables/locales";
import { type EventPage } from "@/schemas/event-page";

const { formatDateRange } = useLocales();

const props = defineProps<{ events: EventPage[] }>();
</script>

<template>
  <Splide :options="{ pagination: false }" aria-label="Événements" :has-track="false" class="section-slider-events relative z-slider isolate">
    <SplideTrack>
      <SplideSlide v-for="(event, i) in events" :key="i">
        <UiPageHeader
          class="wp-min-h-screen"
          :title="event.title"
          :subtitle="`${event.schedule.city} - ${formatDateRange(event.schedule.start_date, event.schedule.end_date)}`"
          :image="event.image"
          :color="event.details.color"
          :h1="!i"
        >
          <div class="self-stretch flex justify-between items-center gap-6">
            <a :href="event.details.registration_url" class="btn-outline" target="_blank">{{ __("Registration", "gaspesia") }}</a>
            <a :href="event.url" class="link-arrow-right">{{ __("Learn more", "gaspesia") }}</a>
          </div>
        </UiPageHeader>
      </SplideSlide>
    </SplideTrack>
    <div class="splide__arrows">
      <button class="splide__arrow splide__arrow--prev absolute left-0 bottom-1/3 button-square-left color-light">
        <IconLucideChevronLeft class="icon-2xl -translate-x-1" />
      </button>
      <button class="splide__arrow splide__arrow--next absolute right-0 bottom-1/3 button-square-right color-light">
        <IconLucideChevronRight class="icon-2xl translate-x-1" />
      </button>
    </div>
  </Splide>
</template>
