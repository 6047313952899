export function useLocales() {
  const curLocale = locales.current.code === "fr" ? "fr-CA" : "en-CA";
  function formatDate(value: string, options?: Intl.DateTimeFormatOptions) {
    const date = new Date(value + "T00:00:00");
    const dateFormatter = new Intl.DateTimeFormat(curLocale, {
      year: "numeric",
      month: "long",
      day: "numeric",
      ...options,
    });
    return dateFormatter.format(date);
  }

  function formatDateRange(start: string, end?: string, options?: Intl.DateTimeFormatOptions) {
    const startDate = new Date(start + "T00:00:00");
    const endDate = end ? new Date(end + "T00:00:00") : startDate;
    const dateFormatter = new Intl.DateTimeFormat(curLocale, {
      year: "numeric",
      month: "long",
      day: "numeric",
      ...options,
    });
    return dateFormatter.formatRange(startDate, endDate);
  }

  function formatDateTime(value: string, options?: Intl.DateTimeFormatOptions) {
    const date = new Date(value);
    const dateTimeFormatter = new Intl.DateTimeFormat(curLocale, {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
      ...options,
    });
    return dateTimeFormatter.format(date);
  }

  function formatDateTimeRange(start: string, end?: string, options?: Intl.DateTimeFormatOptions) {
    const startDate = new Date(start);
    const endDate = end ? new Date(end) : startDate;
    const dateTimeFormatter = new Intl.DateTimeFormat(curLocale, {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
      ...options,
    });
    return dateTimeFormatter.formatRange(startDate, endDate);
  }

  function formatTime(value: string, options?: Intl.DateTimeFormatOptions) {
    const [hours, minutes] = value.split(":").map(Number);
    const date = new Date();
    const timeFormatter = new Intl.DateTimeFormat(curLocale, {
      hour: "numeric",
      minute: "numeric",
      hour12: false,
      ...options,
    });
    date.setHours(hours, minutes);
    return timeFormatter.format(date);
  }

  function formatTimeRange(start: string, end?: string) {
    return end ? `${formatTime(start)} → ${formatTime(end)}` : formatTime(start);
  }

  return { ...locales, formatDate, formatDateRange, formatDateTime, formatDateTimeRange, formatTime, formatTimeRange };
}
