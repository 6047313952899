<script setup lang="ts">
import { useResponsive } from "@/composables/responsive";

const { isMobile } = useResponsive();
</script>

<template>
  <footer id="site-footer">
    <div v-if="isMobile" class="bg-mountains-bottom">
      <div class="container py-12 bg-contour-contain">
        <slot />
      </div>
    </div>
    <div class="py-6 lg:py-12" :class="isMobile ? 'dark color-dark' : 'color-light'">
      <div class="container grid sm:grid-cols-2 lg:grid-cols-3 items-end gap-6">
        <SvgLogoGaspesiaDesktop class="mx-auto sm:mx-0 w-48 sm:w-64 fill-current" />
        <SiteFooterMenu class="sm:row-span-2" />
        <div class="sm:order-1 space-y-6">
          <SiteContactDetails />
          <SiteContactSocialMedias class="" />
        </div>
        <div v-if="!isMobile" class="sm:row-span-2"><slot /></div>
      </div>
    </div>
    <div class="py-3 paragraph-small links:link-opacity" :class="!isMobile ? 'dark color-dark' : 'color-light'">
      <div class="container flex flex-col items-center sm:list-divided-x justify-center leading-tight">
        <SiteFooterCopyright />
        <SiteFooterCredits />
        <AuthLink />
      </div>
    </div>
  </footer>
</template>
