<script setup lang="ts">
const value = defineModel<string>();
</script>

<template>
  <div class="w-full relative">
    <input v-model="value" type="search" class="input-search pr-8" :placeholder="__('Search...', 'gaspesia')" />
    <IconLucideX v-if="value" class="absolute right-1.5 top-1/2 -translate-y-1/2 text-muted cursor-pointer" @click="value = ''" />
    <IconLucideSearch v-else class="absolute right-1.5 top-1/2 -translate-y-1/2 text-muted" />
  </div>
</template>
