<script setup lang="ts">
import { useLocales } from "@/composables/locales";
import { type EventScheduleItem } from "@/schemas/event-schedule-day";

const { formatTimeRange } = useLocales();
defineProps<{ item: EventScheduleItem }>();
</script>

<template>
  <div class="event-schedule-item links:link-event flex flex-col sm:flex-row gap-x-6 gap-y-3">
    <div class="sm:min-w-40 heading-subtitle">{{ formatTimeRange(item.start_time, item.end_time) }}</div>
    <div class="space-y-6">
      <div class="paragraph-large">{{ item.title }}</div>
      <Component :is="item.location_url ? 'a' : 'div'" :href="item.location_url" :target="item.location_url && '_blank'" class="flex items-center gap-1.5">
        <IconLucideMapPin class="icon-md text-muted" />
        {{ item.location }}
      </Component>
    </div>
  </div>
</template>
