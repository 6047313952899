import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";

export function useResponsive() {
  const breakpoints = useBreakpoints(breakpointsTailwind);
  const isMobile = breakpoints.smaller("lg");
  const isPhone = breakpoints.smaller("sm");
  return {
    breakpoints,
    isMobile,
    isPhone,
  };
}
