<script setup lang="ts">
import IconInfo from "lucide-static/icons/circle-alert.svg";
import IconWarning from "lucide-static/icons/triangle-alert.svg";
import { tv } from "tailwind-variants";

const props = defineProps<{ variant: "info" | "warning" }>();

const sectionHeroAlertVariants = tv({
  slots: {
    wrapper: "container-lg flex items-center gap-6 py-12",
    icon: "flex-shrink-0 icon-2xl ",
  },
  variants: {
    variant: {
      info: {
        icon: "text-blue-500/50",
      },
      warning: {
        icon: "text-yellow-500/50",
      },
    },
  },
});

const classes = sectionHeroAlertVariants(props);

const icons = {
  info: IconInfo,
  warning: IconWarning,
};
</script>

<template>
  <div class="section-hero-alert">
    <div :class="classes.wrapper()">
      <Component :is="icons[variant]" :class="classes.icon()" />
      <div class="">
        <slot />
      </div>
    </div>
  </div>
</template>
