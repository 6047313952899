import "./css/_main.css";
import "./utils/error-overlay";

import { createApp, type Plugin } from "vue";

declare global {
  const VERSION: string;
}

console.log(`Gaspesia v${VERSION} (${import.meta.env.MODE})`);

// Create Vue.js application from #app root element
const rootElement = document.getElementById("app");
if (!rootElement) {
  throw new Error("Could not find #app HTML element.");
}
export const app = createApp({ name: "AppRoot", template: rootElement.innerHTML });

// Vue.js plugins
const plugins: Record<string, { default: Plugin }> = import.meta.glob("./plugins/*.ts", { eager: true });
Object.keys(plugins).forEach((key) => {
  app.use(plugins[key].default);
});

// Mount Vue.js application
app.mount(rootElement);
